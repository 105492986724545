



























































  import { GENDER } from '@/constant/Enums';
  import { Validator } from '@/constant/Mixins';
  import { Toaster } from '@/constant/Toaster';
  import { ServerResponse } from '@/services/response.types';
  import { AddressBookModel } from '@/store/auth/types';
  import { PaymentProductKesehatanConfirmationModel } from '@/store/product/kesehatan/types';
  import { ConfirmationUserModel, ProductModel, ProductSummaryModel } from '@/store/product/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'personalKesehatan',
    components: {
      PaymentOptions: () => import('@/components/payment/paymentOptions.vue'),
    },
  })
  export default class personalKesehatan extends Mixins(Validator) {
    @Action('CheckuserAuth', { namespace: 'auth' }) CheckuserAuth!: () => Promise<ServerResponse>;
    @Action('GetPaymentConfirmProduct', { namespace: 'product/kesehatan' }) GetPaymentConfirmProduct!: (quotation_id: string) => Promise<ServerResponse>;
    @Action('CheckoutProduct', { namespace: 'product/kesehatan' }) CheckoutProduct!: (params: { quotation_id: string; form: FormData }) => Promise<ServerResponse>;
    @Action('GetAddresBook', { namespace: 'auth' }) GetAddresBook!: () => Promise<ServerResponse>;

    @State('confirmation', { namespace: 'product/kesehatan' }) confirmation!: PaymentProductKesehatanConfirmationModel;
    @State('address', { namespace: 'auth' }) addressList!: AddressBookModel[];

    public showModal = false;

    public get user(): ConfirmationUserModel {
      let user = this.confirmation.user;
      user.gender = GENDER.filter((g) => String(g.value) === user.gender)[0]?.text ?? '-';
      return user;
    }

    public get product(): ProductModel {
      return this.confirmation.product;
    }

    public get address(): AddressBookModel {
      return this.addressList[0] ?? new AddressBookModel();
    }

    public summary: { label?: string; value: any; label_class?: string; value_class?: string; row_class?: string; label_colspan?: string; value_colspan?: string; show_label?: boolean; html_value?: boolean }[] = [];
    public transaction_id: string = null;
    public confirmed = false;

    public created(): void {
      this.GetAddresBook();
    }

    public mounted(): void {
      this.GetPaymentConfirmProduct(this.$route.query.q as string).then((res) => {
        this.user.address = this.address.address;
        this.user.shipping_address = this.address.address;
        this.user.shipping_reciever = this.address.reciever;
        this.user.zipcode = this.address.zipcode;
        this.user.shipping_phone = this.address.phone;
      });
    }

    /**
     * OnSubmit
     */
    public OnSubmit() {
      if (this.confirmed) {
        let form = new FormData();
        let user = this.user.GetOnly(['first_name', 'last_name', 'idcard', 'gender', 'born_date', 'occupation', 'email', 'phone', 'address', 'city_id', 'state_id', 'country_id', 'zipcode', 'shipping_reciever', 'shipping_phone', 'shipping_address']);

        Object.keys(user).forEach((key) => form.append(key, user[key]));

        this.CheckoutProduct({ quotation_id: this.$route.query.q as string, form }).then((res) => {
          // Toaster.Make(res.messages, { variant: 'success', title: 'Payment Complete!' });
          // this.$router.push({ name: 'transaction-history' });

          let summ = res.data.summary;

          this.summary = [];
          this.summary.push({ label: 'Perusahaan', value: summ.provider_name });
          this.summary.push({ label: 'Produk', value: this.product.product_name });
          this.summary.push({ label: 'Premi Dasar', value: this.formatCurrency(summ.basic_premi) });
          this.summary.push({ label: 'Premi Tambahan', value: this.formatCurrency(summ.additional_premium ?? 0) });
          this.summary.push({ label: 'Diskon', value: this.formatCurrency(summ.discount_amount ?? 0) });
          this.summary.push({ label: 'Administrasi', value: this.formatCurrency(summ.admin_fee ?? 0) });
          this.summary.push({ label: 'Total Tagihan', value: this.formatCurrency(summ.total ?? 0), row_class: 'bg-primary text-white' });
          this.summary.push({ show_label: false, value_colspan: '2', html_value: true, value: `<p><b>Alamat Pengiriman Polis</b></p><p>${summ.shipping_address}</p>` });

          this.transaction_id = res.data.enc_trx_id;

          this.showModal = true;
        });
      } else Toaster.Warning('Please Confirm by checking the checkbox!', { title: 'Need Confirmation' });
    }
  }
